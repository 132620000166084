(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Dunes = {
    // All pages
    'common': {
      init: function () {

        // Slide up browser warning on click
        // $('.browsehappy').click(function () {
        //     $(this).slideUp();
        // });

        // Menu hamburger
        $('.toggle').on('click', function () {
            var target = $(this).data('target');
            $(target).toggleClass('open');

            // $(target).stop().toggle();
        });

        $('textarea').each(function (index, element) {
            var $el = $(element),
                count = $('<span/>', {
                    'class': 'chars',
                    'text': $el.val().length,
                });

            $("label[for='" + $el.attr('id') + "']").append(
                $('<span/>', {
                    'class': 'charcount',
                    'text': ' of ' + $el.attr('maxlength') + ' characters)'
                }).prepend('(', count)
            );

            var counting = false;
            $el.on('input change keyup blur', function () {
                if (!counting) {
                    counting = true;
                    count.text($el.val().length);
                    counting = false;
                }
            });

            $el.on('blur', function () {
                if ($el.val().length > $el.attr('maxlength')) {
                    $el.val(
                        $el.val().substring(0, $el.attr('maxlength'))
                    );

                    count.text($el.val().length);
                }
            });
        });

      },
      finalize: function () {
      }
    },
    // Home page
    'page_home': {
      init: function () {

        var photosPaused = false;

        function setHeroHeight() {
            $('.hero-content').height($('.slider-container').height());
        };

        setHeroHeight();
        $(window).on('resize', setHeroHeight);

        // Play first video on load
        $('.hero .slider').on('init', function (event, slick) {
            var v = $(this).find('[data-slick-index="' + slick.slickCurrentSlide() + '"] video');
            if (v.length) {
                v[0].play();
            }
        });

        // Fade out and reload current video on change slide
        $('.hero .slider').on('beforeChange', function (event, slick, currentSlide) {
            var v;
            $(this).find('[data-slick-index="' + currentSlide + '"]').fadeTo('fast', 0);
            v = $(this).find('[data-slick-index="' + currentSlide + '"] video');
            if (v.length) {
                v[0].load();
            }
        });

        // Play next video after slide change
        $('.hero .slider').on('afterChange', function (event, slick, currentSlide) {
            var v = $(this).find('[data-slick-index="' + currentSlide + '"] video');
            if (v.length) {
                v[0].play();
            }
        });

        // Move to next slide after video end
        $('.hero .slider video').on('ended', function () {
            $('.hero .slider').slick('slickNext');
        });

        // Home page hero slider
        $('.hero .slider').slick({
            autoplaySpeed: 6500,
            dots: true,
            pauseOnHover: false,
            pauseOnDotsHover: true,
            useTransform: true,
            useCSS: true,
            fade: true,
            waitForAnimate: true,
            prevArrow: $('#hero-arrows .slider-prev'),
            nextArrow: $('#hero-arrows .slider-next'),
            appendDots: $('#hero-dots'),
            dotsClass: 'slider-dots-nav'
            // cssEase: 'easeInCubic'
        });

        // Pause on slider arrow hover
        $('.hero .slider-prev, .hero .slider-next').on('mouseenter', function() {
            if (!photosPaused) {
                $('.hero .slider').slick('slickPause');
            }
        }).on('mouseleave', function() {
            if (!photosPaused) {
                $('.hero .slider').slick('slickPlay');
            }
        });

        $('.hero .slider-scroll').on('click', function() {
            $('html, body').animate({
                scrollTop: $('#siteFooter').offset().top
            }, 500);
        });

        // Toggle all info panels
        $('.slider-slide, .slider-info').on('click', function(event) {
            event.stopPropagation();
            $('.slider-info').toggleClass('slider-info-open');
        });

      }
    },
    'page_info': {
        init: function () {

            // Open video modal
            $('#video-btn').on('click', function () {
                $('body').addClass('modal-open');
                $('.video-modal').fadeIn({
                    duration: 1000,
                    complete: function () {
                        $('#dunesVideo')[0].play();
                    }
                });
            });

            // Close video modal
            $('#video-closer .slider-close').on('click', function() {
                $('.video-modal').fadeOut({
                    duration: 1000,
                    complete: function () {
                        $('body').removeClass('modal-open');
                        $('#dunesVideo')[0].load();
                    }
                });
            });

        }
    },
    // Photos page
    'page_photos': {
      init: function () {

        var photosPaused = false;

        // INTERIOR slider
        $('#photos-int .slider').slick({
            autoplaySpeed: 6500,
            lazyLoad: 'progressive',
            pauseOnHover: false,
            pauseOnDotsHover: true,
            useTransform: true,
            useCSS: true,
            waitForAnimate: false,
            prevArrow: $('#photos-int .slider-prev'),
            nextArrow: $('#photos-int .slider-next'),
        });

        // INTERIOR - Pause on arrow hover
        $('#photos-int .slider-prev, .photos-modal .slider-next').on('mouseenter', function() {
            if (!photosPaused) {
                $('#photos-int .slider').slick('slickPause');
            }
        }).on('mouseleave', function() {
            if (!photosPaused) {
                $('#photos-int .slider').slick('slickPlay');
            }
        });

        // INTERIOR - Play/Pause slider
        $('#photos-int .slider').on('click', function () {
            var $this = $(this);
            if (photosPaused) {
                $(this).slick('slickPlay');
                photosPaused = false;
                $('#photos-int .slider-play').fadeIn({
                    duration: 400,
                    complete: function () {
                        $this.slick('slickNext');
                        $('#photos-int .slider-play').fadeOut(400);
                    }
                });
            } else {
                $this.slick('slickPause');
                photosPaused = true;
                $('#photos-int .slider-pause').fadeIn({
                    duration: 400,
                    complete: function () {
                        $('#photos-int .slider-pause').fadeOut(400);
                    }
                });
            }
        });

        // INTERIOR - Open modal
        $('#photos-int-btn').on('click', function () {
            $('body').addClass('modal-open');
            $('#photos-int').fadeIn({
                duration: 1000,
                start: function () {
                    $('#photos-int .slider')
                        .slick('slickGoTo', 0, true)
                        .slick('setPosition');
                },
                complete: function () {
                    $('#photos-int .slider').slick('slickPlay');
                }
            });
        });

        // INTERIOR - Close modal
        $('#photos-int .slider-close').on('click', function() {
            $('#photos-int').fadeOut({
                duration: 1000,
                complete: function() {
                    $('#photos-int .slider').slick('slickPause');
                    $('body').removeClass('modal-open');
                }
            });
        });

        // EXTERIOR slider
        $('#photos-ext .slider').slick({
            autoplaySpeed: 6500,
            lazyLoad: 'progressive',
            pauseOnHover: false,
            pauseOnDotsHover: true,
            useTransform: true,
            useCSS: true,
            waitForAnimate: false,
            prevArrow: $('#photos-ext .slider-prev'),
            nextArrow: $('#photos-ext .slider-next'),
        });

        // EXTERIOR - Pause on arrow hover
        $('#photos-ext .slider-prev, .photos-modal .slider-next').on('mouseenter', function() {
            if (!photosPaused) {
                $('#photos-ext .slider').slick('slickPause');
            }
        }).on('mouseleave', function() {
            if (!photosPaused) {
                $('#photos-ext .slider').slick('slickPlay');
            }
        });

        // EXTERIOR - Play/Pause slider
        $('#photos-ext .slider').on('click', function () {
            var $this = $(this);
            if (photosPaused) {
                $(this).slick('slickPlay');
                photosPaused = false;
                $('#photos-ext .slider-play').fadeIn({
                    duration: 400,
                    complete: function () {
                        $this.slick('slickNext');
                        $('#photos-ext .slider-play').fadeOut(400);
                    }
                });
            } else {
                $this.slick('slickPause');
                photosPaused = true;
                $('#photos-ext .slider-pause').fadeIn({
                    duration: 400,
                    complete: function () {
                        $('#photos-ext .slider-pause').fadeOut(400);
                    }
                });
            }
        });

        // EXTERIOR - Open modal
        $('#photos-ext-btn').on('click', function () {
            $('body').addClass('modal-open');
            $('#photos-ext').fadeIn({
                duration: 1000,
                start: function () {
                    $('#photos-ext .slider')
                        .slick('slickGoTo', 0, true)
                        .slick('setPosition');
                },
                complete: function () {
                    $('#photos-ext .slider').slick('slickPlay');
                }
            });
        });

        // EXTERIOR - Close modal
        $('#photos-ext .slider-close').on('click', function() {
            $('#photos-ext').fadeOut({
                duration: 1000,
                complete: function() {
                    $('#photos-ext .slider').slick('slickPause');
                    $('body').removeClass('modal-open');
                }
            });
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Dunes;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
